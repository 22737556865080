import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import WAFormulateErrors from '~/../common/components/Error/WAFormulateErrors'
import FlexSelect from '~/../common/components/FlexSelect/FlexSelect'
Vue.component('WAFormulateErrors', WAFormulateErrors)
Vue.component('FlexSelect', FlexSelect)

Vue.use(VueFormulate, {
  locales: {
    en: {
      required({
        name,
        vm: {
          $attrs: { placeholder },
          $options: {
            propsData: { label },
          },
        },
      }) {
        /* Display a human-readable label if possible. */
        name = label || placeholder || name
        return `${name.replace(/\*\s*$/, '').trim()} is required.`
      },
    },
  },
  slotComponents: {
    errors: 'WAFormulateErrors',
  },
  library: {
    flexSelect: {
      classification: 'text',
      component: 'FlexSelect',
      slotProps: {
        component: [
          'searchable',
          'clearable',
          'labelFieldNames',
          'valueKey',
          'labelKey',
          'optionMapper',
        ],
      },
    },
  },
})
