import Vue from 'vue'
const state = () => {
  return {
    workGroups: {},
  }
}

const mutations = {
  SET_GROUP_ISDOINGWORK(state, groupName) {
    ensureWorkGroup(state, groupName)
    state.workGroups[groupName].isDoingWorkCounter += 1
  },
  SET_GROUP_ISDONEDOINGWORK(state, groupName) {
    ensureWorkGroup(state, groupName)
    state.workGroups[groupName].isDoingWorkCounter -= 1
    if (state.workGroups[groupName].isDoingWorkCounter < 0) {
      state.workGroups[groupName].isDoingWorkCounter = 0
    }
  },
  SET_GROUP_CLEAR_ALL(state, groupName) {
    ensureWorkGroup(state, groupName)
    state.workGroups[groupName].isDoingWorkCounter = 0
  },
}

const actions = {
  increaseWorkgroupBusyCounter({ commit }, groupName) {
    if (!groupName) return

    commit('SET_GROUP_ISDOINGWORK', groupName)
  },
  decreaseWorkgroupBusyCounter({ commit }, groupName) {
    if (!groupName) return

    commit('SET_GROUP_ISDONEDOINGWORK', groupName)
  },
  async processGroupWork(
    { commit, state },
    options = { groupName: null, work: null }
  ) {
    if (
      !options ||
      !options.groupName ||
      !options.work ||
      typeof options.work !== 'function'
    ) {
      throw new Error(
        '[workGroupTracker.js] Attempted to call processGroupWork without a groupName or work function'
      )
    }
    commit('SET_GROUP_ISDOINGWORK', options.groupName)
    let didIncrease = false
    try {
      return await new Promise((resolve, reject) => {
        const result = options.work()
        if (result.then) {
          result.then(resolve, reject).finally(() => {
            didIncrease = true
            commit('SET_GROUP_ISDONEDOINGWORK', options.groupName)
          })
        } else {
          didIncrease = false
          commit('SET_GROUP_ISDONEDOINGWORK', options.groupName)
          resolve(result)
        }
      })
    } finally {
      if (didIncrease) {
        commit('SET_GROUP_ISDONEDOINGWORK', options.groupName)
      }
    }
  },
}

const getters = {
  isWorkGroupBusy: (state) => (groupName) => {
    if (!hasGroup(state.workGroups, groupName)) {
      return false
    }
    return state.workGroups[groupName].isDoingWorkCounter > 0
  },
  busyWorkgroups: (state) => {
    return state
  },
}

// #region Helpers
const hasGroup = (groups, groupName) => {
  return Object.hasOwn(groups, groupName)
}
const ensureWorkGroup = (state, groupName) => {
  if (!hasGroup(state.workGroups, groupName)) {
    Vue.set(state.workGroups, groupName, {
      isDoingWorkCounter: 0,
    })
  }
}
// #endregion

export default {
  state,
  mutations,
  actions,
  getters,
}
