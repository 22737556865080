<template>
  <ul v-if="visibleErrors.length" :class="outerClass">
    <li
      v-for="error in visibleErrors"
      :key="error"
      :class="itemClass"
      v-html="htmlError(error)"
    />
  </ul>
</template>

<script>
// Copied from @braid/vue-formulate/src/FormulateErrors.vue and passed into Formulate as a custom error class inside plugins/vue-formulate.js.
// This might break once we upgrade Formulate from current version (2.4.1)
// See https://github.com/wearebraid/vue-formulate/issues/128 for new method once Formulate is upgraded.

export default {
  inject: {
    observeErrors: {
      default: false,
    },
    removeErrorObserver: {
      default: false,
    },
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: [String, Boolean],
      default: 'form',
    },
  },
  data() {
    return {
      boundSetErrors: this.setErrors.bind(this),
      localErrors: [],
    }
  },
  computed: {
    visibleValidationErrors() {
      return Array.isArray(this.context.visibleValidationErrors)
        ? this.context.visibleValidationErrors
        : []
    },
    errors() {
      return Array.isArray(this.context.errors) ? this.context.errors : []
    },
    mergedErrors() {
      return this.errors.concat(this.localErrors)
    },
    visibleErrors() {
      return Array.from(
        new Set(this.mergedErrors.concat(this.visibleValidationErrors))
      ).filter((message) => typeof message === 'string')
    },
    outerClass() {
      const type = this.type === false ? 'input' : this.type
      if (type === 'input' && this.context.classes) {
        return this.context.classes.errors
      }
      return `formulate-${type}-errors`
    },
    itemClass() {
      const type = this.type === false ? 'input' : this.type
      if (type === 'input' && this.context.classes) {
        return this.context.classes.error
      }
      return `formulate-${type}-error`
    },
  },
  created() {
    // This registration is for <FormulateErrors /> that are used for displaying
    // Form errors in an override position.
    if (
      this.type === 'form' &&
      typeof this.observeErrors === 'function' &&
      !Array.isArray(this.context.errors)
    ) {
      this.observeErrors({ callback: this.boundSetErrors, type: this.type })
    }
  },
  destroyed() {
    if (
      this.type === 'form' &&
      typeof this.removeErrorObserver === 'function' &&
      !Array.isArray(this.context.errors)
    ) {
      this.removeErrorObserver(this.boundSetErrors)
    }
  },
  methods: {
    setErrors(errors) {
      this.localErrors = this.arrayify(errors)
    },
    htmlError(error) {
      return `Error: ${error}`
    },

    // Copied from @braid/vue-formulate/src/lib/utils since we cannot import functions from other modules
    arrayify(item) {
      if (!item) {
        return []
      }
      if (typeof item === 'string') {
        return [item]
      }
      if (Array.isArray(item)) {
        return item
      }
      if (typeof item === 'object') {
        return Object.values(item)
      }
      return []
    },
  },
}
</script>
