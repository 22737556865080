/* eslint no-console: 0 */
import qs from 'qs'

export default function (context) {
  context.$axios.onError((error) => {
    console.error('Axios Error: ' + error)
    // We got logged out
    if (process.client && error.response.status === 401) {
      context.$auth.reset()
    }
  })

  context.$axios.onRequest((config) => {
    if (process.server) {
      console.log(`Making ${config.method} request to ${config.url}`)
    }
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })

  context.$axios.onResponse((response) => {
    if (process.server && process.env.NUXT_LOG_AXIOS_RESPONSE) {
      console.log(
        `Received ${response.status} response: ${JSON.stringify(response.data)}`
      )
    }
  })
}
