<template>
  <div class="fs__options_container">
    <div
      :class="{
        'is-wrapping': columnsAreWrapping,
        fs__options_container_inner: true,
      }"
    >
      <template v-if="!Boolean(option)">
        <div class="fs_options_value_column empty">&nbsp;</div>
      </template>
      <template v-else>
        <div
          v-for="fieldName in labelFieldNames"
          :key="fieldName"
          class="fs__options_value_column"
        >
          <div class="fs__options_value">
            {{ getFieldValue(option, fieldName) }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="needsArrowSpace" class="fs__options_value_arrow_space"></div>
  </div>
</template>
<script>
export default {
  name: 'FlexSelectOption',
  props: {
    needsArrowSpace: {
      type: Boolean,
      required: false,
      default: false,
    },
    columnsAreWrapping: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Object,
      required: false,
      default: () => null,
    },
    labelFieldNames: {
      type: Array,
      required: false,
      default: () => ['label'],
    },
  },
  methods: {
    getFieldValue(option, fieldName) {
      if (!fieldName) {
        fieldName = 'label'
      }

      if (Object.hasOwn(option, fieldName)) {
        return option[fieldName]
      } else {
        console.warn('[flex-select-option] Field not found:', fieldName)
        return ''
      }
    },
  },
}
</script>
