import _ from 'lodash'
import moment from 'moment'

const gtagId = typeof window !== 'undefined' ? window.gtagId : null
const currency = 'USD'

const _noUndef = (obj) => _.pickBy(obj, (p) => p !== undefined)

const _getPrice = (product) =>
  parseFloat(product.price_excl_tax || product.retail_price || 0)
const _getTotalPrice = (products) => _.sum(_.map(products, (p) => _getPrice(p)))

const _getItem = (product) =>
  _noUndef({
    item_id: product.product_id,
    price: _getPrice(product),
    name: product.title,
    sku: product.uuid,
    brand: product.producing_winery,
  })

const track = (eventName, eventParameters, action = 'event') => {
  if (typeof window !== 'undefined' && window.gtag) {
    eventParameters = _noUndef(eventParameters)
    /* eslint-disable */
    console.log(`ga4.${action}`, eventName, eventParameters)
    window.gtag(action, eventName, eventParameters)
  }
}

const config = ({ user } = {}) => {
  if (!gtagId) return
  const params = {}
  if (user && user.analytics_id) {
    params.user_id = user.analytics_id
  }
  track(gtagId, params, 'config')
}

const trackSearch = ({ query, count }) => {
  track('search_executed', { query, count })
}

const trackAddToCart = ({ product, quickAdd }) => {
  if (!product) return
  track('add_to_cart', {
    currency,
    value: product.unit_price * product.quantity,
    items: [
      {
        ..._getItem(product),
        price: product.unit_price,
        quantity: product.quantity,
      },
    ],
    quick_add: quickAdd,
  })
}

const trackRemoveFromCart = ({ product }) => {
  if (!product) return
  track('remove_from_cart', {
    currency,
    value: _getPrice(product),
    items: {
      ..._getItem(product),
      quantity: product.quantity,
      price: product.unit_price,
    },
  })
}

const trackViewCart = ({ products, quickAdd }) => {
  if (!products) return
  track('view_cart', {
    currency,
    value: _getTotalPrice(products),
    items: _.map(products, (p) => ({
      ..._getItem(p),
      quantity: p.quantity,
      price: p.unit_price,
    })),
    quick_add: quickAdd,
  })
}

const trackBeginCheckout = ({ products }) => {
  if (!products) return
  track('begin_checkout', {
    currency,
    value: _getTotalPrice(products),
    items: _.map(products, (p) => ({
      ..._getItem(p),
      quantity: p.quantity,
      price: p.unit_price,
    })),
  })
}

const trackViewItem = ({ product }) => {
  if (!product) return
  track('view_item', {
    currency,
    value: _getPrice(product),
    items: [_getItem(product)],
  })
}

const trackViewItemList = ({ path, name, products }) => {
  products = products || []
  track('view_item_list', {
    item_list_id: path,
    item_list_name: name,
    items: _.map(products, (p) => _getItem(p)),
  })
}

const trackPurchase = (order, $auth) => {
  const theOrder = order.ppto_order || order
  const taxTotal = order.ppto_order
    ? order.ppto_order.taxes
    : order.child_tax_total
  let transactionID = order.child_order_id
  if (order.ppto_order) {
    transactionID = `${transactionID}-${moment().format('DDMMYYHHmmss')}`
  }

  track('purchase', {
    transaction_id: transactionID,
    value: parseFloat(theOrder.child_total_revenue),
    tax: parseFloat(taxTotal),
    shipping: parseFloat(order.child_shipping_total),
    currency: 'USD',
    items: theOrder.lines.map((line) => ({
      item_id: line.id,
      item_name: line.title,
      price: parseFloat(line.unit_price_excl_tax),
      quantity: parseInt(line.quantity, 10),
      item_category: line.product.varietal_category_display,
    })),
  })
}

const trackViewClub = ({ slug }) => {
  if (!slug) return
  track('view_club', { slug })
}

export default {
  track,
  trackSearch,
  trackViewItem,
  trackAddToCart,
  trackRemoveFromCart,
  trackViewCart,
  trackBeginCheckout,
  trackViewItemList,
  trackPurchase,
  trackViewClub,

  config,
}
