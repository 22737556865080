<template>
  <div id="error-500">
    <div class="error-500">
      <div class="oops">
        <h1 role="alert">Oops</h1>
      </div>
      <h2>Server Error</h2>
      <p>
        We have notified our team and are working to fix this issue.
      </p>
      <a href="/">Back to Homepage</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error500Component',
}
</script>

<style scoped>
#error-500 {
  position: relative;
  height: 100vh;
}

#error-500 .error-500 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-500 {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.error-500 .oops {
  height: 280px;
  position: relative;
  z-index: -1;
}

.error-500 .oops h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 130px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: cover;
  background-position: center;
  color: #1a1a1a;
}

.error-500 h2 {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.error-500 p {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.error-500 a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 767px) {
  .error-500 .oops {
    height: 142px;
  }
  .error-500 .oops h1 {
    font-size: 112px;
  }
}
</style>
