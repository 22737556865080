export const isPaymentMethodPayPal = (card) => {
  return !!card.email
}
export const isPaymentMethodApplePay = (card) => {
  return card.card_type.startsWith('Apple Pay')
}
export const isApplePaySupported = (window) => {
  if (window && window.ApplePaySession) {
    return (
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()
    )
  }
  return false
}
export const isPaymentMethodCC = (card) => {
  return !isPaymentMethodPayPal(card) && !isPaymentMethodApplePay(card)
}
