const state = () => ({
  myCellarPageFirstLoad: true,
})

const mutations = {
  SET_MY_CELLAR_PAGE_FIRST_LOAD(state, payload) {
    state.myCellarPageFirstLoad = payload
  },
}
const actions = {
  setMyCellarPageFirstLoad({ commit }, firstLoad) {
    commit('SET_MY_CELLAR_PAGE_FIRST_LOAD', firstLoad)
  },
}

const get = (key) => (state) => state[key]

const getters = {
  myCellarPageFirstLoad: get('myCellarPageFirstLoad'),
}

export default {
  state,
  mutations,
  actions,
  getters,
}
