const INITIAL_DATA = {
  addressVerificationState: {
    modal: {
      selectedOption: 'verifySuggestedAddress',
      show: false,
    },
    suggestedAddress: null,
    enteredAddress: null,
    confirmClicked: 0,
  },
}

const state = () => Object.assign({}, INITIAL_DATA)

const mutations = {
  SET_ADDRESS_VERIFICATION_STATE(state, addressVerificationState) {
    state.addressVerificationState = Object.assign(
      {},
      state.addressVerificationState,
      addressVerificationState
    )
    if (state.addressVerificationState.suggestedAddress === 'None') {
      state.addressVerificationState.suggestedAddress = null
    }
    if (
      !state.addressVerificationState.suggestedAddress &&
      state.addressVerificationState.enteredAddress
    ) {
      state.addressVerificationState.modal.selectedOption =
        'verifyEnteredAddress'
    }
  },
  CONFIRM_CLICKED(state) {
    state.addressVerificationState.confirmClicked += 1
  },
}
const actions = {
  setAddressVerificationState({ commit }, addressVerificationState) {
    commit('SET_ADDRESS_VERIFICATION_STATE', addressVerificationState)
  },
  addressVerificationConfirmClicked({ commit }) {
    commit('CONFIRM_CLICKED')
  },
  resetAddressVerificationModal({ commit }) {
    commit('SET_ADDRESS_VERIFICATION_STATE', {
      modal: {
        selectedOption: 'verifySuggestedAddress',
        show: false,
      },
      suggestedAddress: null,
      enteredAddress: null,
    })
  },
  closeAddressVerificationModal({ commit }) {
    commit('SET_ADDRESS_VERIFICATION_STATE', {
      modal: {
        selectedOption: 'verifySuggestedAddress',
        show: false,
      },
    })
  },
}

const getters = {
  addressVerificationState(state) {
    return state.addressVerificationState
  },
  addressVerificationConfirmClicked(state) {
    return state.addressVerificationState.confirmClicked
  },
  addressVerificationSelectedAddress(state) {
    return state.addressVerificationState.modal.selectedOption ===
      'verifySuggestedAddress'
      ? state.addressVerificationState.suggestedAddress
      : state.addressVerificationState.enteredAddress
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
