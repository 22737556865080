import ga4 from './ga4'

// Set current user
export async function loadUser({ $auth, $axios, $cookies, store, query, res }) {
  // We pass through the query args to trigger the tracking middleware
  const result = await $axios.get('/api/customer/user/', { params: query })
  setUser($auth, result.data)

  // If we're logged in, but the session is not set, logout
  if (
    $auth.loggedIn &&
    !$cookies.get('wa-xd-sessionid') &&
    !$cookies.get('sessionid')
  ) {
    await logoutUser($auth, store)
    // Update cart
    if (store._actions.getCart) store.dispatch('getCart')
  }
}

export function pushEvent($axios, data, store) {
  if (data.extra_data) {
    data.extra_data.mobile_app = store.getters.inMobileApp
  } else {
    data.extra_data = { mobile_app: store.getters.inMobileApp }
  }
  try {
    $axios.post('/api/logic/esp-push/', data)
  } catch (err) {
    console.error(err)
  }
}

export function pushPageView($axios, data) {
  $axios.post('/api/logic/esp-page/', data)
}

export class RateLimitedException extends Error {
  constructor(message, cause) {
    super(message)
    this.cause = cause
    this.name = 'RateLimitedException'
  }
}

export class OneTimeTokenRequiredException extends Error {
  constructor(message, cause) {
    super(message)
    this.cause = cause
    this.name = 'OneTimeTokenRequiredException'
  }
}

export class OneTimeTokenExpiredException extends Error {
  constructor(message, cause) {
    super(message)
    this.cause = cause
    this.name = 'OneTimeTokenExpiredException'
  }
}

export class OneTimeTokenInvalidException extends Error {
  constructor(message, cause) {
    super(message)
    this.cause = cause
    this.name = 'OneTimeTokenInvalidException'
  }
}

export async function login(
  store,
  $axios,
  $auth,
  $cookies,
  email,
  password,
  params
) {
  const loginParams = {
    email,
    password,
  }
  if (params.path) loginParams.path = params.path
  if (params.recaptcha) loginParams.recaptcha = params.recaptcha
  if (params.oneTimeToken) {
    delete loginParams.password
    loginParams.otp_token = params.oneTimeToken
  }
  let response = {}
  try {
    response = await $axios.post('/api/customer/login/', loginParams)
  } catch (e) {
    if (e.message && e.message.includes('status code 429')) {
      throw new RateLimitedException(
        'Too many login attempts. Please try again later or reset your password by clicking "Forgot Your Password?" below.'
      )
    } else if (
      e.response.data.otp_token &&
      e.response.data.otp_token[0] === 'invalid'
    ) {
      throw new OneTimeTokenInvalidException(
        'Secure Login Code provided is invalid, please try again'
      )
    } else if (
      e.response.data.otp_token &&
      e.response.data.otp_token[0] === 'expired'
    ) {
      throw new OneTimeTokenExpiredException(
        'Secure Login Code provided has expired, please enter your credentials again'
      )
    }
    throw e
  }
  if (response.data.otp_required) {
    throw new OneTimeTokenRequiredException(
      'Secure Login Code is required for login'
    )
  }
  const user = await $axios.get('/api/customer/user/')
  ga4.config({ user: user.data })
  setUser($auth, user.data, response.token)
  if (params) {
    const loggedInFromLoginModal = !!params.loggedInFromLoginModal
    $auth.$storage.setState(
      'userLoggedInFromLoginModal',
      loggedInFromLoginModal
    )
  }
  setUserLoaded($auth, true, process.server)
  if (store._actions.getCart) await store.dispatch('getCart')
  if (store._actions.getPromoBanner) await store.dispatch('getPromoBanner')
  if (store._actions.fetchMetadata) await store.dispatch('fetchMetadata')
  if (store._actions.fetchPPTOEligibleOrder)
    await store.dispatch('fetchPPTOEligibleOrder')
  identifyUser($auth, $cookies)
}

export function setUser($auth, user, token, params) {
  if (params) {
    // Flag to indicate if this user just registered
    const registeredFromLoginModal = !!params.registeredFromLoginModal
    $auth.$storage.setState(
      'userRegisteredFromLoginModal',
      registeredFromLoginModal
    )
  }
  $auth.$storage.setState('user', user)
  $auth.$storage.setState('loggedIn', user && user.is_authenticated)
  if (token) {
    $auth.setUserToken(token)
  }
  setUserLoaded($auth, true, process.server)
}

// Indicate that the user has been loaded
export function setUserLoaded($auth, userLoaded, userLoadedOnServer) {
  $auth.$storage.setState('userLoaded', userLoaded)
  $auth.$storage.setState('userLoadedOnServer', userLoadedOnServer)
}

export async function logoutUser($axios, $auth, $store, $router) {
  await $axios.get('/api/customer/logout/')
  const user = await $axios.get('/api/customer/user/')
  ga4.config({ user: user.data })
  setUser($auth, user.data)
  // Unset notifications
  if ($store._actions.setNotifications) $store.dispatch('setNotifications', [])
  if ($store._actions.getCart) $store.dispatch('getCart')
  if ($store._actions.getPromoBanner) $store.dispatch('getPromoBanner', [])

  // If on an account or cellar page, redirect to the store
  if (
    $router.currentRoute.path.includes('/accounts/') ||
    $router.currentRoute.path.includes('/cellar/')
  ) {
    $router.push('/')
  }
}

export function userLoaded($auth) {
  return $auth.$storage.getState('userLoaded')
}

export function userLoadedOnServer($auth) {
  return $auth.$storage.getState('userLoadedOnServer')
}

export function userRegisteredFromLoginModal($auth) {
  return $auth.$storage.getState('userRegisteredFromLoginModal')
}

export function userLoggedInFromLoginModal($auth) {
  return $auth.$storage.getState('userLoggedInFromLoginModal')
}

// Need to init all $auth storage vars, otherwise Vue reactivity won't trigger
export function initAuthStorage($auth) {
  $auth.$storage.setState('loggedIn', false)
  $auth.$storage.setState('userRegisteredFromLoginModal', false)
  $auth.$storage.setState('userLoggedInFromLoginModal', false)
  // Set false so we know on the client we didn't actually fetch the user data yet
  $auth.$storage.setState('userLoaded', false)
  $auth.$storage.setState('userLoadedOnServer', false)
}

export function getAge(dateString) {
  if (!dateString) {
    return null
  }
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export function getGAIDs($cookies) {
  /* eslint-disable camelcase */
  $cookies.get('_ga')
  let ga_client_id = null
  try {
    ga_client_id = $cookies.get('_ga').split('.').slice(-2).join('.')
  } catch (error) {}

  const gaCookies = Object.fromEntries(
    Object.entries($cookies.getAll()).filter(([key, value]) =>
      key.startsWith('_ga_')
    )
  )

  let ga_session_id = null
  if (Object.keys(gaCookies).length === 1) {
    try {
      ga_session_id = gaCookies[Object.keys(gaCookies)[0]].split('.')[2]
    } catch (error) {}
  }
  const ids = {}
  if (ga_client_id) ids.ga_client_id = ga_client_id
  if (ga_session_id) ids.ga_session_id = ga_session_id
  return ids
  /* eslint-enable camelcase */
}

export function identifyUser($auth, $cookies) {
  if (!window.analytics) return
  const data = getGAIDs($cookies)
  Object.assign(data, {
    name: `${$auth.user.first_name} ${$auth.user.last_name}`,
    firstName: $auth.user.first_name,
    lastName: $auth.user.last_name,
    email: $auth.user.email,
    age: getAge($auth.user.date_of_birth),
    zipcode: $auth.user.zipcode,
  })
  window.analytics.identify($auth.user.analytics_id, data)
}

export function possessiveTitleUserName($auth) {
  if (!$auth || !$auth.user || !$auth.user.first_name) {
    return null
  }
  return `${$auth.user.first_name
    .charAt(0)
    .toUpperCase()}${$auth.user.first_name.slice(1)}${
    $auth.user.first_name.endsWith('s') ? "'" : "'s"
  }`
}
