const state = () => ({
  orders: [],
  pptoEligibleOrder: null,
  pptoEligibleOrderCount: 0,
  orderCount: 0,
  giftOrders: [],
  giftOrderCount: 0,
  orderPageFirstLoad: true,
})

const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload.orders
    state.orderCount = payload.orderCount
  },
  UPDATE_ORDER(state, { oldOrder, newOrder }) {
    if (!oldOrder) {
      state.orders.push(newOrder)
      state.orderCount += 1
    } else {
      Object.assign(oldOrder, newOrder)
    }
  },
  UPDATE_PPTO_ORDER(state, payload) {
    state.pptoEligibleOrder = payload
  },
  UPDATE_PPTO_ORDER_COUNT(state, payload) {
    state.pptoEligibleOrderCount = payload
  },
  SET_GIFT_ORDERS(state, payload) {
    state.giftOrders = payload.results
    state.giftOrderCount = payload.count
  },
  SET_ORDERS_PAGE_FIRST_LOAD(state, payload) {
    state.orderPageFirstLoad = payload
  },
}

const actions = {
  clearOrders({ commit }) {
    commit('SET_ORDERS', {
      orders: [],
      orderCount: 0,
    })
  },
  fetchOrders({ commit }, params) {
    return this.$axios.get('/api/customer/order/', { params }).then((resp) => {
      commit('SET_ORDERS', {
        orders: resp.data.results,
        orderCount: resp.data.count,
      })
    })
  },
  fetchGiftOrders({ commit }, params) {
    return this.$axios
      .get('/api/customer/gift-order/', { params })
      .then((resp) => {
        commit('SET_GIFT_ORDERS', {
          results: resp.data.results,
          count: resp.data.count,
        })
      })
  },
  fetchOrder({ commit, state }, orderId) {
    return this.$axios.get('/api/customer/order/' + orderId).then((resp) => {
      const oldOrder = state.orders.find((o) => o.id === orderId)
      const newOrder = resp.data
      commit('UPDATE_ORDER', { oldOrder, newOrder })
    })
  },
  fetchPPTOEligibleOrder({ commit }) {
    return this.$axios
      .get('/api/customer/order/', { params: { filter: 'ppto' } })
      .then((resp) => {
        commit('UPDATE_PPTO_ORDER', resp.data.count ? resp.data.results[0] : {})
      })
  },
  fetchPPTOEligibleOrderCount({ commit }) {
    return this.$axios
      .get('/api/customer/ppto-eligible-total/')
      .then((resp) => {
        commit('UPDATE_PPTO_ORDER_COUNT', resp.data.total || 0)
      })
  },
  setOrdersPageFirstLoad({ commit }, firstLoad) {
    commit('SET_ORDERS_PAGE_FIRST_LOAD', firstLoad)
  },
}

const get = (key) => (state) => state[key]

const getters = {
  orders: get('orders'),
  giftOrders: get('giftOrders'),
  pptoEligibleOrder: get('pptoEligibleOrder'),
  pptoEligibleOrderCount: get('pptoEligibleOrderCount'),
  orderCount: get('orderCount'),
  giftOrderCount: get('giftOrderCount'),
  ordersPageCount: (state) => Math.ceil(state.orderCount / 10),
  giftOrdersPageCount: (state) => Math.ceil(state.giftOrderCount / 10),
  orderPageFirstLoad: get('orderPageFirstLoad'),
}

export default {
  state,
  mutations,
  actions,
  getters,
}
