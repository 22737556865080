<template>
  <div v-if="loading" class="loading-page">
    <Spinner />
  </div>
</template>

<script>
import Spinner from '~/../common/components/Loading/Spinner.vue'
export default {
  name: 'LoadingComponent',
  components: { Spinner },
  props: {
    initial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { loading: this.initial }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
