<template>
  <div :class="siteAbbrClass">
    <img
      v-lazy-load data-src="~assets/images/wa-loader.gif"
      class="wa-loader-animation"
      data-not-lazy
      alt=""
    />
    <img
      v-lazy-load data-src="~assets/images/svg/wa-loader.svg"
      alt="Wine glass swirling"
      role="img"
      data-not-lazy
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    siteAbbr: {
      type: String,
      default: 'wa',
      required: false,
    },
  },
  computed: {
    // Computed property to generate a class name based on the siteAbbr prop
    siteAbbrClass() {
      return `${this.siteAbbr}-loader` // Adjust the suffix as needed
    },
  },
}
</script>
