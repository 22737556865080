import Vue from 'vue'

const VueLinebreaks = Vue.filter('linebreaks', function (value) {
  if (!value) return ''
  const innerP = '</p><p>'
  // Ignore extra consecutive linebreaks
  const val = value
    .toString()
    .replace('\n\n\n\n', innerP)
    .replace('\n\n\n', innerP)
    .replace('\n\n', innerP)
    .replace('\n', innerP)
  return '<p>' + val + '</p>'
})

Vue.use(VueLinebreaks)
