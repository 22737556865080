export const slugify = (value) => {
  return value.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_')
}

export function deslugify(slug) {
  let name = slug.replace(/-/g, ' ')
  name = name.substr(0, 1).toUpperCase() + name.substr(1)
  return Array.from(name)
    .map((letter, index) => {
      if (name.substr(index - 1, 1) === ' ') {
        return letter.toUpperCase()
      } else {
        return letter
      }
    })
    .join('')
}
