export const baseURL = () => {
  if (process.client) {
    return window.location.origin
  } else {
    return process.env.BASE_URL
  }
}

export const canonicalPDPBaseURL = () => {
  return process.env.CANONICAL_PDP_BASE_URL
}

export const fullURL = (route) => {
  if (process.client) {
    return baseURL() + window.location.pathname
  } else {
    return baseURL() + route.path
  }
}

export const routeFromUrl = (url) => {
  const fullUrlRegex = /([^?]*)(\?(.*))?/
  const fullUrlMatch = fullUrlRegex.exec(url)
  const path = fullUrlMatch[1]
  const queryString = fullUrlMatch[3]
  const queryAsObject = {}
  if (queryString) {
    const querySubStrings = queryString.split('&')
    const singleQueryParamRegex = /(.+?)=(.*)/
    const queryParamKeyValuePairArray = querySubStrings.map((p) =>
      singleQueryParamRegex.exec(p).slice(1)
    )
    queryParamKeyValuePairArray.forEach(([paramKey, paramVal]) => {
      queryAsObject[paramKey] = paramVal
    })
  }
  return {
    path,
    query: queryAsObject,
  }
}

export const urlFromRoute = (route) => {
  const base = `${route.path}`
  const query =
    (Object.keys(route.query).length > 0 ? '?' : '') +
    Object.entries(route.query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  return `${base}${query}`
}

const magicUrlSearchQueryKeys = new Set(['ordering', 'page'])

const magicUrlFilterCategories = new Set([
  'category__in',
  'varietals',
  'countries',
  'is_offer__in',
  'regions',
  'occasions',
  'wineries',
])

export const searchUrlToMagicUrl = (searchFilters, url) => {
  const route = routeFromUrl(url)
  const queryParamKeys = Object.keys(route.query)
  const magicUrlFilterCategoriesCount = queryParamKeys.filter((k) =>
    magicUrlFilterCategories.has(k)
  ).length
  const allQueryParamsValidForMagicUrls = queryParamKeys.every(
    (k) => magicUrlSearchQueryKeys.has(k) || magicUrlFilterCategories.has(k)
  )
  const atSearchUrl = route.path === '/store/search/'
  const atRootSearchUrl =
    route.path === '/store/search/' &&
    queryParamKeys.every((k) => magicUrlSearchQueryKeys.has(k))
  const magicUrlBaseCategory = queryParamKeys.find((k) =>
    magicUrlFilterCategories.has(k)
  )
  const magicUrlFilterKey = (Object.entries(route.query).find(([k, v]) =>
    magicUrlFilterCategories.has(k)
  ) || [])[1]
  const searchFiltersForFilterCategory = searchFilters.filter(
    (f) =>
      f.category === magicUrlBaseCategory ||
      (route.query.category__in && f.category === 'categories') ||
      (route.query.is_offer__in && f.category === 'is_offer')
  )
  const urlFilterKeyExistsInSearchFilters =
    searchFiltersForFilterCategory
      .map((f) => f.key)
      .includes(magicUrlFilterKey) ||
    (magicUrlBaseCategory === 'is_offer__in' &&
      searchFiltersForFilterCategory
        .map((f) => f.key_as_string)
        .includes(magicUrlFilterKey))
  if (
    ((magicUrlFilterCategoriesCount === 1 &&
      (urlFilterKeyExistsInSearchFilters ||
        magicUrlBaseCategory === 'regions')) ||
      atRootSearchUrl) &&
    allQueryParamsValidForMagicUrls &&
    atSearchUrl
  ) {
    const query = [
      [
        'ordering',
        (route.query.ordering !== '-popularity_ranking' ||
          route.query.is_offer__in) &&
          route.query.ordering,
      ],
      ['page', route.query.page !== '1' && route.query.page],
    ]
      .filter((q) => q[1])
      .reduce((obj, [key, value]) => Object.assign(obj, { [key]: value }), {})
    if (atRootSearchUrl) {
      return urlFromRoute({ path: '/store/', query })
    } else {
      return urlFromRoute({
        path: `/store/${
          route.query.category__in ||
          (route.query.is_offer__in && `is_offer/${magicUrlFilterKey}`) ||
          `${
            magicUrlBaseCategory === 'countries'
              ? 'regions'
              : magicUrlBaseCategory
          }/${route.query[magicUrlBaseCategory]}`
        }/`,
        query,
      })
    }
  } else {
    return url
  }
}

export const isIntraStoreNavigation = ({
  to = '',
  from = '',
  history = null,
} = {}) => {
  /* Determine if this routing is within the store by providing `to` and `from` URL
     strings or an HTML5History `history` parameter */

  if (history) {
    if (!(history.pending && history.current)) {
      return false
    } else {
      to = history.pending.path
      from = history.current.path
    }
  }

  const plpLikePages = ['/store/', '/checkout/add-to-order/']
  return (
    to &&
    from &&
    plpLikePages.some((plpUrl) => {
      return to.indexOf(plpUrl) === 0 && from.indexOf(plpUrl) === 0
    })
  )
}

export const isAnchorNavigation = (to, from) => {
  return !!to.hash
}
